@import '../stylesheets/choices';

@tailwind base;
@tailwind components;
@tailwind utilities;

.turbo-progress-bar {
  @apply bg-indigo-600
}

/* Disable with replacement */
button                  .show-when-disabled { display: none; }
button[disabled]        .show-when-disabled { display: initial; }

button                  .show-when-enabled { display: initial; }
button[disabled]        .show-when-enabled { display: none; }

/* Pagy css classes */

.pagy {
  @apply flex space-x-1 text-sm text-gray-500 dark:text-white;
}
.pagy > :not([hidden]) ~ :not([hidden]) {
  --space-reverse: 0;
  margin-right: calc(0.25rem * var(--space-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--space-reverse)));
}
.pagy a:not(.gap) {
  @apply block rounded-lg px-3 py-1 bg-gray-200 dark:bg-gray-900;
}
.pagy a:not(.gap):hover {
  @apply text-white bg-indigo-500;
}
.pagy a:not(.gap):not([href]) { /* disabled links */
  @apply text-gray-300 bg-gray-100 cursor-default dark:bg-gray-700;
}
.pagy a:not(.gap).current, .page-item.active a {
  @apply text-white bg-indigo-600 dark:bg-indigo-600;
}
.pagy label {
  @apply inline-block whitespace-nowrap bg-gray-200 rounded-lg px-3 py-0.5;
}
.pagy label input {
  @apply bg-gray-100 border-none rounded-md;
}

/* List.js sorting style */

th.sort {
  @apply cursor-pointer;
}

th.sort:hover {
  @apply bg-gray-50;
}

th.sort.asc::after {
  background-image: url(../images/sort.svg);
  background-size: 0.75rem 0.75rem;
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  content:"";
}

th.sort.desc::after {
  background-image: url(../images/sort.svg);
  background-size: 0.75rem 0.75rem;
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  content:"";
}

/* Custom classes for form error management */

.label-error {
  @apply text-red-700 dark:text-red-500
}

.label-classic {
  @apply text-gray-900 dark:text-white
}

.input-error {
  @apply block w-full bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 w-full py-2 dark:bg-red-100 dark:border-red-400
}

/* Any change here should be reflected below in .choices_inner class */
.input-classic {
  @apply block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-600 dark:ring-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
}

.checkbox-classic {
  @apply h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600
}

.error-message {
  @apply mt-2 text-sm text-red-600 dark:text-red-500
}

/* Toast color management */

.toast-error-color {
  @apply bg-red-500 dark:bg-red-800
}

.toast-success-color {
  @apply bg-green-500 dark:bg-green-800
}

.toast-button-error-color {
  @apply hover:bg-red-200 dark:bg-red-800 dark:hover:bg-red-700
}

.toast-button-success-color {
  @apply hover:bg-green-200 dark:bg-green-800 dark:hover:bg-green-700
}

/* Tab active management */

.tab-inactive {
  @apply border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300;
}

.tab-active {
  @apply text-blue-600 dark:text-blue-500 border-blue-600 dark:border-blue-500;
}

/* Choices js custom css */

.choices__inner {
  @apply bg-white block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 sm:text-sm sm:leading-6 dark:bg-gray-600 dark:ring-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
}

.choices__list--dropdown .choices__list {
  max-height: 200px;
}

/* Spinner */

.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 100px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #333;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}
